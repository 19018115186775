import { IRequestContext } from '@msdyn365-commerce/core';
import { debounce } from 'lodash';
import { observer } from 'mobx-react';
import * as React from 'react';
import { IIncrementalQuantityProps } from '@msdyn365-commerce-modules/utilities/dist/types/incremental-quantity';
import {
    getPayloadObject,
    getTelemetryAttributes,
    IPayLoad,
    isMobile,
    TelemetryConstant,
    VariantType
} from '@msdyn365-commerce-modules/utilities';
import { subtract, sum } from '../utilities/helpers';

/**
 * IIncrementalQuantityState interface.
 */
interface IIncrementalQuantityState {
    currentInput: string;
    isUpdating: boolean;
}

/* start of extension */
interface ExtendedIIncrementalQuantityProps extends IIncrementalQuantityProps {
    onValueChangeTriggered?: () => void;
    onlyIntegerForQuantity: boolean;
}
/* end of extension */

/**
 * Quantity Component - This component is used to add or remove quantity.
 */
@observer
export default class IncrementalQuantity extends React.PureComponent<ExtendedIIncrementalQuantityProps, IIncrementalQuantityState> {
    public static defaultProps: Partial<ExtendedIIncrementalQuantityProps> = {
        min: 1,
        decrementGlyphClass: 'fas fa-minus',
        incrementGlyphClass: 'fas fa-plus'
    };

    private _minValue: number;
    private _step: number;

    private readonly payLoad: IPayLoad;

    public static getDerivedStateFromProps(
        props: Readonly<ExtendedIIncrementalQuantityProps>,
        state: IIncrementalQuantityState
    ): IIncrementalQuantityState {
        const nextState = { ...state };

        if (props.disabled && !state.isUpdating) {
            nextState.isUpdating = true;
        }

        if (
            (!props.disabled && state.isUpdating) ||
            ((props.applyDefaultOrderSettings ?? false) &&
                Number(state.currentInput) === 1 &&
                props.currentCount &&
                props.currentCount > IncrementalQuantity.defaultProps.min!)
        ) {
            nextState.currentInput = (props.currentCount ?? 1).toString();
            nextState.isUpdating = false;
        }

        return nextState;
    }

    constructor(props: ExtendedIIncrementalQuantityProps) {
        super(props);
        this.state = { currentInput: (props.currentCount ?? 1).toString(), isUpdating: false };
        this._onIncrement = this._onIncrement.bind(this);
        this._onDecrement = this._onDecrement.bind(this);
        this._handleChange = this._handleChange.bind(this);
        this.payLoad = getPayloadObject('click', this.props.telemetryContent!, '');
        this._minValue = this.props.min ?? IncrementalQuantity.defaultProps.min!;
        this._step = 1;
    }

    public render(): JSX.Element {
        const { max } = this.props;

        const glyphMinusClassName: string = `${this.props.decrementGlyphClass!} quantity__controls-glyph`;
        const glyphPlusClassName: string = `${this.props.incrementGlyphClass!} quantity__controls-glyph`;
        const decrementDisabled = Number(this.state.currentInput) <= this._minValue || this.props.isGiftCard;
        const incrementDisabled = Number(this.state.currentInput) >= max || this.props.isGiftCard;
        this.payLoad.contentAction.etext = TelemetryConstant.DecrementQuantity;
        const decrementAttributes = getTelemetryAttributes(this.props.telemetryContent!, this.payLoad);
        this.payLoad.contentAction.etext = TelemetryConstant.IncrementQuantity;
        const incrementAttributes = getTelemetryAttributes(this.props.telemetryContent!, this.payLoad);
        const ariaLablelText = `${this.props.inputQuantityAriaLabel ?? ''} ${this.state.currentInput}`;

        let extraClassDecrement = '';
        if (decrementDisabled) {
            // The quantity has reached its boundaries (max or min)
            extraClassDecrement = 'disabled';
        } else if (this.props.disabled) {
            // This.props.disabled shows if the state is not succeded yet
            extraClassDecrement = 'transition';
        }
        let extraClassIncrement = '';
        if (incrementDisabled) {
            // The quantity has reached its boundaries (max or min)
            extraClassIncrement = 'disabled';
        } else if (this.props.disabled) {
            // This.props.disabled shows if the state is not succeded yet
            extraClassIncrement = 'transition';
        }

        return (
            <>
                <div className='quantity' id={this.props.id}>
                    <button
                        disabled={this.props.disabled || decrementDisabled}
                        title={decrementDisabled ? '' : this.props.decrementButtonAriaLabel}
                        className={`decrement quantity__controls ${extraClassDecrement}`}
                        onClick={this._onDecrement}
                        aria-hidden={!!decrementDisabled}
                        aria-label={`${this.props.decrementButtonAriaLabel}`}
                        color='secondary'
                        {...decrementAttributes}
                    >
                        <span className={glyphMinusClassName} />
                    </button>
                    <input
                        className='quantity-input'
                        value={this.state.currentInput}
                        onChange={this._handleChange}
                        onBlur={this._validateMin}
                        aria-live='polite'
                        aria-label={this.isMobileView() ? ariaLablelText : `${this.props.inputQuantityAriaLabel ?? ''}`}
                        role='spinbutton'
                        aria-valuemin={this._minValue}
                        aria-valuemax={max}
                        disabled={this.props.disabled}
                        min={this._minValue}
                        max={max}
                    />
                    <button
                        disabled={this.props.disabled || incrementDisabled}
                        title={incrementDisabled ? '' : this.props.incrementButtonAriaLabel}
                        className={`increment quantity__controls ${extraClassIncrement}`}
                        onClick={this._onIncrement}
                        aria-hidden={!!incrementDisabled}
                        aria-label={`${this.props.incrementButtonAriaLabel}`}
                        color='secondary'
                        {...incrementAttributes}
                    >
                        <span className={glyphPlusClassName} />
                    </button>
                </div>
            </>
        );
    }

    /**
     * It checks if its in mobile view or not.
     * @returns Boolean.
     */
    private readonly isMobileView = (): boolean => {
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- Added gridsetting values.
        const context = {
            gridSettings: {
                xs: { w: 767 },
                sm: { w: 991 },
                md: { w: 1199 },
                lg: { w: 1599 },
                xl: { w: 1600 }
            }
        } as IRequestContext;

        const isMobileCheck = isMobile({ variant: VariantType.Browser, context });
        return isMobileCheck === 'xs' || isMobileCheck === 'sm';
    };

    private _onIncrement(): void {
        let invokeCallback = false;
        const currQuantity = Number(this.state.currentInput);
        let updatedQuantity: number;

        if (currQuantity < this.props.max) {
            invokeCallback = true;

            /* start of extension */
            updatedQuantity = sum(currQuantity, this._step, 10);
            /* end of extension */
        } else {
            invokeCallback = false;
            updatedQuantity = this.props.max;
        }

        /* start of extension */
        if (invokeCallback && this.props.onChange) {
            if (updatedQuantity > this.props.max) {
                this.props.onChange?.(this.props.max);
                this.setState({ currentInput: this.props.max.toString() });
            } else {
                this.props.onChange?.(updatedQuantity);
                this.setState({ currentInput: updatedQuantity.toString() });
            }
        }
        /* end of extension */
    }

    private _onDecrement(): void {
        let invokeCallback = false;
        const currQuantity = Number(this.state.currentInput);
        let updatedQuantity: number;

        if (currQuantity >= this._minValue) {
            invokeCallback = true;

            /* start of extension */
            updatedQuantity = subtract(currQuantity, this._step, 10);
            /* end of extension */
        } else {
            invokeCallback = false;
            updatedQuantity = this._minValue;
        }

        /* start of extension */
        if (invokeCallback && this.props.onChange) {
            if (updatedQuantity < this._minValue) {
                this.props.onChange?.(this._minValue);
                this.setState({ currentInput: this._minValue.toString() });
            } else {
                this.props.onChange?.(updatedQuantity);
                this.setState({ currentInput: updatedQuantity.toString() });
            }
        }
        /* end of extension */
    }

    /* start of extension */
    private _debouncedChangeHandler = debounce((value: string) => {
        const currentValue = Math.min(this.props.max, Math.max(this._minValue, Number(value))).toString();
        this.setState({ currentInput: currentValue });

        // Due of usage debouncer, we may ignore onChange return value
        this.props.onChange?.(Number(currentValue));
    }, 700);

    private _handleChange(e: React.ChangeEvent<HTMLInputElement>): void {
        let currentValue = e.target.value;
        let isValid = false;

        this.props.onValueChangeTriggered?.();

        if (this.props.onlyIntegerForQuantity) {
            // Remove leading zeros
            currentValue = (+e.target.value).toString();
            isValid = /^\d*$/.test(currentValue);
        } else {
            // validate positive integers and decimals without leading zeros
            if (/^\d*$/.test(currentValue)) {
                isValid = true;
                // Remove leading zeros
                currentValue = (+e.target.value).toString();
            } else if (/^(0(\.\d*)?|[1-9]\d*(\.\d*)?)$/.test(currentValue)) {
                isValid = true;
            }
        }

        if (isValid) {
            this.setState({ currentInput: currentValue });
            this._debouncedChangeHandler(currentValue);
        }
    }
    /* end of extension */

    /**
     * Validates min and updates.
     */
    private readonly _validateMin = (): void => {
        if (Number.isNaN(this.state.currentInput)) {
            this.setState({ currentInput: this._minValue.toString() });
        } else {
            const currentValue = Math.min(this.props.max, Math.max(this._minValue, Number(this.state.currentInput)));
            if (currentValue !== Number(this.state.currentInput)) {
                this.setState({ currentInput: currentValue.toString() });
            }
        }
    };
}
