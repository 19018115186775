import * as React from 'react';

import { ExtendedUnitOfMeasureOption } from '../types';
import { calculateQuantityForUnitsOfMeasurementAsync } from '../proxy/actions/calculate-quantity-for-units-of-measurement.action';
import { ICartLineProps } from '../modules/tr-cart/components/cartlineitem.component';
import {
    checkQuantityForUnitOfMeasure,
    checkQuantityForUnitOfMeasureOptions,
    findDefaultUnitOfMeasureOption,
    updateUnitOfMeasureOptions
} from '../utilities/units-of-measure';
import { UnitOfMeasureConversionOption } from '../proxy/entities/UnitOfMeasureConversionOption';

export const useUnitOfMeasureOptions = (props: ICartLineProps) => {
    const [recalculatedDefaultUOMQuantity, setRecalculatedDefaultUOMQuantity] = React.useState<number>(props.currentQuantity ?? 0);
    const [unitOfMeasureOptions, setUnitOfMeasureOptions] = React.useState<ExtendedUnitOfMeasureOption[]>([]);
    const [isUnitOfMeasureOptionsLoading, setIsUnitOfMeasureOptionsLoading] = React.useState(false);
    const [areQuantitiesCalculationInProgress, setAreQuantitiesCalculationInProgress] = React.useState(false);
    const cartlineId = props.data.cartLine?.LineId ?? '';

    const getProductUnitOfMeasureOptions = async (productId: number, quantity: number, defaultUnitOfMeasure: string): Promise<void> => {
        setIsUnitOfMeasureOptionsLoading(true);

        try {
            const options = await calculateQuantityForUnitsOfMeasurementAsync(
                { callerContext: props.context.actionContext },
                productId,
                defaultUnitOfMeasure,
                quantity
            );

            if (options?.length) {
                const mappedOptions: ExtendedUnitOfMeasureOption[] = options.map(option => ({
                    ...option,
                    ...(props.maxQuantity && { MaxQuantity: (option.Quantity / quantity) * props.maxQuantity }),
                    MinQuantity: option.Quantity / quantity
                }));

                if (props.isOnlyFullOrderAllowed) {
                    const isQuantityInvalid = checkQuantityForUnitOfMeasureOptions(mappedOptions, props.unitOfMeasureWithFullOrder);

                    if (isQuantityInvalid) {
                        props.setCartlinesQuantityErrors(cartlineId, true);
                    }
                }

                setUnitOfMeasureOptions(mappedOptions);
            }
        } catch (e) {
            console.log(e);
        } finally {
            setIsUnitOfMeasureOptionsLoading(false);
        }
    };

    const updateCartlineQuantity = (quantity: number, defaultUnitOfMeasureOption?: UnitOfMeasureConversionOption) => {
        if (defaultUnitOfMeasureOption) {
            props.quantityOnChange?.(props.data.cartLine, defaultUnitOfMeasureOption.Quantity);
        } else {
            props.quantityOnChange?.(props.data.cartLine, quantity);
        }
    };

    const calculateQuantityForUnitsOfMeasurement = async (
        productId: number,
        quantity: number,
        fromUnitOfMeasure: string
    ): Promise<void> => {
        try {
            setAreQuantitiesCalculationInProgress(true);
            props.setCartlinesQuantityErrors(cartlineId, false);

            const recalculatedUnitsOfMeasure = await calculateQuantityForUnitsOfMeasurementAsync(
                { callerContext: props.context.actionContext },
                productId,
                fromUnitOfMeasure,
                quantity
            );

            if (recalculatedUnitsOfMeasure?.length && unitOfMeasureOptions?.length) {
                // keeping the original logic and updating quantity for the default unit of measure
                const defaultUnitOfMeasureOption = findDefaultUnitOfMeasureOption(
                    recalculatedUnitsOfMeasure,
                    props.data?.product?.DefaultUnitOfMeasure
                );

                // updating quantities for additional units of measure
                const updatedUnitOfMeasureOptions = updateUnitOfMeasureOptions(
                    unitOfMeasureOptions,
                    recalculatedUnitsOfMeasure,
                    quantity,
                    fromUnitOfMeasure
                );

                if (props.isOnlyFullOrderAllowed) {
                    const isQuantityOfUnitOfMeasureInvalid = checkQuantityForUnitOfMeasure(
                        quantity,
                        fromUnitOfMeasure,
                        props.unitOfMeasureWithFullOrder
                    );
                    const isQuantityOfUnitOfMeasureOptionsInvalid = checkQuantityForUnitOfMeasureOptions(
                        updatedUnitOfMeasureOptions,
                        props.unitOfMeasureWithFullOrder
                    );

                    if (isQuantityOfUnitOfMeasureInvalid || isQuantityOfUnitOfMeasureOptionsInvalid) {
                        props.setCartlinesQuantityErrors(cartlineId, true);
                        setRecalculatedDefaultUOMQuantity(defaultUnitOfMeasureOption?.Quantity || quantity);
                    } else {
                        updateCartlineQuantity(quantity, defaultUnitOfMeasureOption);
                    }
                } else {
                    updateCartlineQuantity(quantity, defaultUnitOfMeasureOption);
                }

                setUnitOfMeasureOptions(updatedUnitOfMeasureOptions);
            }
        } catch (e) {
            console.log(e);
        } finally {
            setAreQuantitiesCalculationInProgress(false);
        }
    };

    React.useEffect(() => {
        if (!isUnitOfMeasureOptionsLoading && props.data?.product && props.currentQuantity) {
            const productId = props.data.product.MasterProductId ?? props.data.product.RecordId;
            const unitOfMeasure = props.data.product.DefaultUnitOfMeasure;

            if (productId && unitOfMeasure) {
                getProductUnitOfMeasureOptions(productId, props.currentQuantity, unitOfMeasure);
            }
        }
    }, []);

    return {
        recalculatedDefaultUOMQuantity,
        unitOfMeasureOptions,
        isUnitOfMeasureOptionsLoading,
        areQuantitiesCalculationInProgress,
        getProductUnitOfMeasureOptions,
        calculateQuantityForUnitsOfMeasurement
    };
};
