import { CommerceProperty } from '@msdyn365-commerce/retail-proxy';

import { TR_UOM_WITH_FULL_ORDER } from '../constants';
import { ExtendedUnitOfMeasureOption } from '../types';
import { UnitOfMeasureConversionOption } from '../proxy/entities/UnitOfMeasureConversionOption';
import { findPropertyByKey } from './extension-properties-helpers';

export const updateUnitOfMeasureOptions = (
    currentUnitOfMeasureOptions: ExtendedUnitOfMeasureOption[],
    recalculatedUnitOfMeasureOptions: UnitOfMeasureConversionOption[],
    quantity: number,
    fromUnitOfMeasure: string
) => {
    const result = currentUnitOfMeasureOptions.map(option => {
        if (option.ToUnitOfMeasure === fromUnitOfMeasure) {
            return { ...option, Quantity: quantity };
        }

        const recalculatedOption = recalculatedUnitOfMeasureOptions.find(
            ({ ToUnitOfMeasure }) => option.ToUnitOfMeasure === ToUnitOfMeasure
        );

        if (recalculatedOption) {
            return { ...option, Quantity: recalculatedOption.Quantity };
        }

        return option;
    });

    return result;
};

export const findDefaultUnitOfMeasureOption = (
    recalculatedUnitOfMeasureOptions: UnitOfMeasureConversionOption[],
    defaultUnitOfMeasure?: string
) => {
    const result = recalculatedUnitOfMeasureOptions.find(updatedUnit => updatedUnit.ToUnitOfMeasure === defaultUnitOfMeasure);

    return result;
};

export const getUnitOfMeasureWithFullOrder = (extensionProperties?: CommerceProperty[]): string => {
    if (!extensionProperties?.length) {
        return '';
    }

    return findPropertyByKey(TR_UOM_WITH_FULL_ORDER, extensionProperties)?.Value?.StringValue ?? '';
};

export const checkQuantityForUnitOfMeasure = (quantity: number, fromUnitOfMeasure?: string, unitOfMeasureWithFullOrder?: string) =>
    fromUnitOfMeasure?.toLowerCase() === unitOfMeasureWithFullOrder?.toLowerCase() && !Number.isInteger(quantity);

export const checkQuantityForUnitOfMeasureOptions = (
    unitOfMeasureOptions: ExtendedUnitOfMeasureOption[],
    unitOfMeasureWithFullOrder?: string
) =>
    unitOfMeasureOptions.some(
        option => option.ToUnitOfMeasure.toLowerCase() === unitOfMeasureWithFullOrder?.toLowerCase() && !Number.isInteger(option.Quantity)
    );
