const handleFixedPointOperation = (
    operation: (a: number, b: number) => number,
    number1?: number,
    number2?: number,
    fractionDigits = 2
): number => {
    if (typeof number1 !== 'number' || typeof number2 !== 'number') {
        return 0;
    }

    const factor = Math.pow(10, fractionDigits);
    const num1 = Math.round(number1 * factor);
    const num2 = Math.round(number2 * factor);

    return operation(num1, num2) / factor;
};

export const sum = (number1?: number, number2?: number, fractionDigits = 2): number => {
    return handleFixedPointOperation((a, b) => a + b, number1, number2, fractionDigits);
};

export const subtract = (number1?: number, number2?: number, fractionDigits = 2): number => {
    return handleFixedPointOperation((a, b) => a - b, number1, number2, fractionDigits);
};
